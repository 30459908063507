<template>
  <modal class="modal--notes-create" @close="close">
    <template v-slot:title>
      Создание примечания
    </template>
    <template v-slot:body>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <div class="modal__lang">
            <div class="modal__lang-item">
              <v-radio
                v-model="lang"
                :val="langs[0].value"
              >
                {{ langs[0].text }}
              </v-radio>
            </div>
            <div class="modal__lang-item">
              <v-radio
                v-model="lang"
                :val="langs[1].value"
              >
                {{ langs[1].text }}
              </v-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-select
            v-model="type"
            :options="types"
            :error="errors.type"
            placeholder="Тип анализа"
            required
          />
        </div>
        <div class="modal__form-col">
          <v-select
            v-model="antibody_type"
            :options="antibody_types"
            :error="errors.antibody_type"
            placeholder="Тип анализа на антитела"
            required
          />
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-input
            v-model="table"
            :error="errors.table"
            placeholder="Оборудование, реактив, срок годности"
            required
          />
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-input
            v-model="footer"
            :error="errors.footer"
            placeholder="Нижнее примечание"
            required
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <v-button type="submit" @click.native="close" class="gray">
        Отмена
      </v-button>
      <v-button type="submit" @click.native="save">
        Сохранить
      </v-button>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../components/ui/Modal"
import VSelect from "../../../components/ui/VSelect";
import VButton from "../../../components/ui/VButton";
import VInput from "../../../components/ui/VInput";
import VRadio from "../../../components/ui/VRadio";
import {create as createNote} from "@/api/note/create";

export default {
  data() {
    return {
      lang: 'ru',
      type: null,
      antibody_type: null,
      table: null,
      footer: null,

      langs: [
        {value: 'ru', text: 'RU'},
        {value: 'en', text: 'EN'},
      ],
      types: [
        {value: 'pcr', text: 'ПЦР'},
        {value: 'antibody', text: 'Антитела'},
      ],
      antibody_types: [
        {value: 'm', text: 'M'},
        {value: 'gs', text: 'G (капсидный белок)'},
      ],

      errors: {},
    }
  },
  components: {
    Modal,
    VSelect,
    VButton,
    VInput,
    VRadio,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      createNote({
        lang: this.lang,
        type: this.type,
        antibody_type: this.antibody_type,
        table: this.table,
        footer: this.footer,
      })
        .then(() => {
          this.getNotify('success', 'Создано');
          this.$parent.loadItems();
          this.close();
        })
        .catch(error => {
          if ('id' in error.response.data.errors) {
            this.getNotify('error', error.response.data.errors.id[0]);
          }
          this.errors = error.response.data.errors;
        })
    },
  }
}
</script>

<style lang="scss">
.modal--notes-create {
  .modal__wrapper {
    width: 836px;
    max-width: 100%;
  }

  .modal__lang {
    display: flex;
    margin-bottom: 26px;
  }

  .modal__lang-item {
    margin-right: 30px;
  }
}
</style>

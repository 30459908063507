<template>
  <modal @close="close">
    <template v-slot:title>
      Удаление примечания
    </template>
    <template v-slot:body>
      <div class="modal__form-text">
        Вы уверены что хотите удалить примечание?
      </div>
    </template>
    <template v-slot:footer>
      <v-button type="submit" @click.native="close" class="gray">
        Отмена
      </v-button>
      <v-button type="submit" @click.native="del">
        Удалить
      </v-button>
    </template>
  </modal>
</template>


<script>
import Modal from "@/components/ui/Modal";
import VButton from "@/components/ui/VButton";
import {destroy as deleteNote} from "@/api/note/destroy";

export default {
  props: {
    row: Object,
  },
  components: {
    Modal,
    VButton,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    del() {
      deleteNote(this.row.id)
        .then(() => {
          this.getNotify('success', 'Удалено');
          this.$parent.loadItems();
          this.close();
        })
        .catch(error => {
          this.getErrorNotify(error);
        })
    }
  }
}
</script>

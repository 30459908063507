<template>
  <modal class="modal--notes-create" @close="close">
    <template v-slot:title>
      Редактирование примечания
    </template>
    <template v-slot:body>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <div class="modal__lang">
            <div class="modal__lang-item">
              <v-radio
                v-model="lang"
                :val="langs[0].value"
              >
                {{ langs[0].text }}
              </v-radio>
            </div>
            <div class="modal__lang-item">
              <v-radio
                v-model="lang"
                :val="langs[1].value"
              >
                {{ langs[1].text }}
              </v-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-select
            v-model="type"
            :options="types"
            placeholder="Тип анализа"
            required
          />
        </div>
        <div class="modal__form-col">
          <v-select
            v-model="antibody_type"
            :options="filtered_antibody_types"
            placeholder="Тип анализа на антитела"
            required
          />
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-input
            v-model="table"
            placeholder="Оборудование, реактив, срок годности"
            required
          />
        </div>
      </div>
      <div class="modal__form-row">
        <div class="modal__form-col">
          <v-input
            v-model="footer"
            placeholder="Нижнее примечание"
            required
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <v-button type="submit" @click.native="close" class="gray">
        Отмена
      </v-button>
      <v-button type="submit" @click.native="save">
        Сохранить
      </v-button>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../components/ui/Modal"
import VSelect from "../../../components/ui/VSelect";
import VButton from "../../../components/ui/VButton";
import VInput from "../../../components/ui/VInput";
import VRadio from "../../../components/ui/VRadio";
import {update as updateNote} from "@/api/note/update";

export default {
  props: {
    row: Object,
  },
  components: {
    Modal,
    VSelect,
    VButton,
    VInput,
    VRadio,
  },
  data() {
    return {
      lang: this.row.lang.value,
      type: this.row.type.value,
      antibody_type: this.row.antibody_type ? this.row.antibody_type.value : '',
      table: this.row.table,
      footer: this.row.footer,

      langs: [
        {value: 'ru', text: 'Русский'},
        {value: 'en', text: 'Английский'},
      ],
      types: [
        {value: 'pcr', text: 'ПЦР'},
        {value: 'antibody', text: 'Антитела'},
      ],
      antibody_types: [
        {value: 'g', text: 'G'},
        {value: 'm', text: 'M'},
        {value: 'gs', text: 'G (капсидный белок)'},
      ],
    }
  },
  computed: {
    filtered_antibody_types() {
      return this.antibody_types.filter(item => ['m', 'gs', this.antibody_type].indexOf(item.value) !== -1)
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      updateNote(this.row.id, {
        lang: this.lang,
        type: this.type,
        antibody_type: this.antibody_type,
        table: this.table,
        footer: this.footer,
      })
        .then(() => {
          this.getNotify('success', 'Изменено');
          this.$parent.loadItems();
          this.close();
        })
        .catch(error => {
          this.getErrorNotify(error);
        })
    },
  }
}
</script>

<template>
  <div>
    <Title>
      Примечания
      <plus-button @click.native="create = true">Создать</plus-button>
    </Title>

    <note-create v-if="create" @close="create = false"/>

    <note-edit v-if="edit" @close="edit = false" :row="row"/>

    <note-delete v-if="del" @close="del = false" :row="row"/>

    <vue-good-table
      ref="vueTable"
      mode="remote"
      styleClass="primary-table"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :pagination-options="paginationOptions"
      :rows="rows"
      :columns="columns"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'footer'" class="relative">
          {{ props.row.footer }}
          <a-dropdown class="primary-table__actions-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay" class="primary-table__dropdown-menu">
              <a-menu-item>
                <a @click="onEdit(props.row)">
                  <a-icon type="edit"/>
                  Изменить
                </a>
              </a-menu-item>
              <a-menu-item>
                <a @click="onDelete(props.row)">
                  <a-icon type="delete"/>
                  Удалить
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div v-else>
          {{ props.formattedRow[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <Pagination
          :total="props.total"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
      <div slot="emptystate" class="text-center">
        Нет данных
      </div>
      <template slot="loadingContent">
        <span class="vgt-loading__content">
            Загрузка...
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import Vue from 'vue';
import {vueTable} from "../../mixins/vue-table";
import Title from "@/components/ui/Title";
import PlusButton from "@/components/ui/PlusButton";
import ADropdown from 'ant-design-vue/lib/dropdown';
import AMenu from 'ant-design-vue/lib/menu';
import AIcon from 'ant-design-vue/lib/icon';
import {getAll} from "@/api/note/getAll";
import NoteCreate from "./modal/Create";
import NoteEdit from "./modal/Edit";
import NoteDelete from "./modal/Delete";
import Pagination from '@/components/ui/Pagination';

Vue.use(AMenu);

export default {
  mixins: [vueTable],
  data() {
    return {
      getAll,
    }
  },
  components: {
    Title,
    PlusButton,
    ADropdown,
    AIcon,
    NoteCreate,
    NoteEdit,
    NoteDelete,
    Pagination
  },
  computed: {
    columns() {
      return [
        {
          field: 'id',
          label: 'ID',
          sortable: true,
          width: '7rem',
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'ID',
            trigger: 'enter',
          },
        },
        {
          field: 'type',
          formatFn: obj => {
            return obj.label;
          },
          label: 'Тип анализа',
          sortable: true,
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Тип анализа',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'pcr', text: 'ПЦР'},
              {value: 'antibody', text: 'Антитела'},
            ],
          },
        },
        {
          field: 'lang',
          formatFn: obj => {
            return obj.label;
          },
          label: 'Язык',
          sortable: true,
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Язык',
            trigger: 'enter',
            filterDropdownItems: [
              {value: 'ru', text: 'Русский'},
              {value: 'en', text: 'Английский'},
            ],
          },
        },
        {
          field: 'table',
          label: 'Оборудование, реактив, срок годности',
          sortable: true,
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Оборудование, реактив, срок годности',
            trigger: 'enter',
          },
        },
        {
          field: 'footer',
          label: 'Нижнее примечание',
          sortable: true,
          tdClass: 'fold',
          filterOptions: {
            enabled: true,
            placeholder: 'Нижнее примечание',
            trigger: 'enter',
          },
        },
      ]
    }
  },
}
</script>

<style lang="scss">
</style>
